$waveTopHeight: clamp(39px, calc(83vw / 7.68), 147px);
$waveBottomHeight: clamp(41px, calc(87vw / 7.68), 156px);

.ContactForm {
  display: grid;
  gap: clamp(12px, calc(20vw / 7.68), 20px) clamp(64px, calc(64vw / 7.68), 124px);
  align-items: center;
  @media (min-width: 1000px) {
    grid-template-columns: 1.2fr 1fr;
  }
  &[data-highlighted='true'] {
    background: var(--primary-300, #fff6f9);
    padding: clamp(12px, calc(12vw / 7.68), 42px) 0;
    margin: $waveTopHeight 0 $waveBottomHeight;
  }
  header {
    @media (max-width: 999px) {
      max-width: calc(587rem / 16);
    }
    .paragraph {
      margin: clamp(12px, calc(20vw / 7.68), 20px) 0 clamp(16px, calc(32vw / 7.68), 32px);
    }
  }
  img {
    width: clamp(154px, calc(307vw / 7.68), 307px);
    margin-left: 20%;
    margin-top: -10%;
    @media (max-width: 599px) {
      margin-left: 40%;
    }
  }
}

.Form {
  position: relative;
  max-width: calc(483rem / 16);
  display: grid;
  gap: clamp(12px, calc(16vw / 7.68), 16px);
  &:has([data-element='FormState']) {
    & > *:not([data-element='FormState']) {
      display: none;
    }
  }
  .legal {
    font-size: 0.8125rem;
    margin-bottom: 0.5rem;
  }
}

.WaveTop,
.WaveBottom {
  position: absolute;
  z-index: -1;
  width: 100vw;
  left: calc(var(--pageMargin) * -1);
  @media (min-width: 1280px) {
    left: calc((100vw - 1200px) * -0.5);
  }
}
.WaveTop {
  bottom: calc(100% - 1px);
  height: $waveTopHeight;
}
.WaveBottom {
  top: calc(100% - 1px);
  height: $waveBottomHeight;
}
