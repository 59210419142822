.Reviews {
  min-width: 0;
  > header {
    max-width: calc(587rem / 16);
    margin: 0 auto clamp(32px, calc(48vw / 7.68), 48px);
    text-align: center;
    .heading {
      margin-bottom: clamp(12px, calc(20vw / 7.68), 20px);
    }
  }
}

.Slider {
  $paddingTop: clamp(10px, calc(13vw / 7.68), 13px);
  padding-top: $paddingTop;
  @media (max-width: 1279px) {
    margin: 0 calc(var(--pageMargin) * -1);
    padding: $paddingTop var(--pageMargin) 0;
  }
  .slide {
    align-self: flex-start;
    margin-right: 24px;
    padding: clamp(12px, calc(24vw / 7.68), 24px);
    border-radius: 20px 20px 20px 0px;
    background: var(--primary-300, #fff6f9);
    position: relative;
    flex: 0 0 calc(100% / 3 - 24px);
    @media (max-width: 999px) {
      flex: 0 0 calc(100% / 1.3);
    }
    .QuoteIcon {
      position: absolute;
      right: 28px;
      height: clamp(20px, calc(26vw / 7.68), 26px);
      top: 0;
      transform: translateY(-50%);
    }
    header {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-size: clamp(calc(12rem / 16), calc(14vw / 7.68), calc(14rem / 16));
        color: var(--primary-700, #e6688d);
      }
    }
    .purchased {
      margin: 6px 0 20px;
      span {
        color: var(--secondary-400, #97879d);
      }
      a {
        transition: opacity 0.3s;
        &:hover {
          opacity: 0.8;
        }
      }
      font-size: clamp(calc(12rem / 16), calc(14vw / 7.68), calc(14rem / 16));
    }
  }
}

.Pagination {
  margin: clamp(24px, calc(32vw / 7.68), 32px) auto 0;
  display: grid;
  width: fit-content;
  grid-template-columns: auto auto auto;
  gap: 28px;
  .dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    button {
      transition:
        transform 0.3s var(--easing),
        opacity 0.3s var(--easing);
      transform: scale(1.5);
      &[aria-current='false'] {
        transform: scale(1);
        opacity: 0.62;
      }
    }
  }
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    z-index: 1;
    svg {
      z-index: 1;
      position: relative;
    }
    &::before {
      z-index: 0;
    }
    background: var(--primary-300, #fff6f9);
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: inherit;
      background: var(--primary-400, #ffd7e4);
      z-index: -1;
      transform: scale(0);
      transition: transform 0.3s var(--easing);
    }
    &:hover,
    &[aria-current='page'] {
      &::before {
        transform: scale(1);
      }
    }
  }
}
